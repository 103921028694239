import React, { useRef,useState,useEffect } from 'react';
import { Container, Row, Col,Form } from 'react-bootstrap';
import HTMLReactParser from "html-react-parser";
import { Link } from 'gatsby';
import { isIOS } from 'react-device-detect';
import "./Banner.scss";
import GoogleRating from '../googleRating/GoogleRating';
import ImageTransform from '../common/ggfx-client/module/components/image-transform';
import { GetURL } from '../utils';
import ReapitBook from '../ReapitBook';
import $ from "jquery";
import IMAGE_TRANSFORMS from "../../../static/images/config.json"


const HomepageBanner = (props) => {
    const isBrowser = typeof window !== "undefined";
    let processedImages = JSON.stringify({});
    if (props?.data?.imagetransforms?.Banner_Image_Transforms) {
        processedImages = props?.data?.imagetransforms?.Banner_Image_Transforms;
    }
    let titleText = "";
    if(props?.data?.Title) {
        titleText = props?.data?.Title.replace(props?.data?.Italic_Text, `<span>${props?.data?.Italic_Text}</span>`)
        titleText = HTMLReactParser(titleText)
    }
    const bannerSize=IMAGE_TRANSFORMS?.articles?.Banner_Image?.sizes
    const keysLength=bannerSize.length
  
    let ImgWidth = 2700, ImageHeight = 1175;
    // for (let index = 0; index < keysLength; index++) {
    //     let sizes = bannerSize[index].split('x') 
    //     //Load image width and height for page ranking improvements
    //     if(isBrowser){
    //       if(window.innerWidth <= parseInt(sizes[0])){
    //         ImgWidth = sizes[0];
    //         ImageHeight = sizes[1];
    //         break;
    //       }
    //     }   
    //   }
    const [renderComponent, setRenderComponent] = useState(false);
      useEffect(() => {
        if (isIOS) {
          setRenderComponent(true)
        }else {
          window.addEventListener("mousemove", () => {
          if (renderComponent === false) {
            setRenderComponent(true)
          }
          })
          window.addEventListener("touchmove", () => {
              if (renderComponent === false) {
              setRenderComponent(true)
              }
          })
          window.addEventListener("keypress", () => {
              if (renderComponent === false) {
              setRenderComponent(true)
              }
          })}
      }, [])
      console.log("ImgWidth",ImgWidth)
      console.log("Height",ImageHeight)
    return (
        <React.Fragment>
            <section className='banner'>
                <Container>
                    <Row>
                        <Col md-12>
                            <div className='banner-content'>
                                <h1>{titleText}</h1>
                                <Form className="banner-form">
                                   <p>{props?.data?.Description}</p>
                                    <div className="input-box">
                                        {props?.modules?.filter(c => c.List_of_Links != undefined)[0]?.List_of_Links.map((url, i) => 
                                            <React.Fragment key={i}>
                                                {url?.Link_Type == "Book_A_Valuation" && 
                                                <> {renderComponent &&
                                                    <ReapitBook className='btn cursor-pointer btn-color' type="[&quot;sales&quot;,&quot;lettings&quot;]">
                                                        {url.Sub_Menu_Label}
                                                    </ReapitBook>
                                                    }
                                                    { !renderComponent &&
                                                     <button className='btn cursor-pointer btn-color'>
                                                        {url.Sub_Menu_Label}
                                                    </button>
                                                    }
                                                </>
                                                }
                                                {url?.Link_Type == "Anchor" && 
                                                    <a href="javascript:;" onClick={() => {
                                                        $('html, body').animate({
                                                            scrollTop: $(url?.Anchor_or_External).offset().top-130
                                                        }, 0)
                                                    }} className='btn cursor-pointer'>{url.Sub_Menu_Label}</a>
                                                }
                                                {url?.Link_Type == "External" && 
                                                    <a href={url?.Anchor_or_External} target="_blank" className='btn cursor-pointer'>{url.Sub_Menu_Label}</a>
                                                }
                                                {url?.Sub_Menu_Link && <Link to={GetURL(url?.Sub_Menu_Link?._id)} className='btn'>{url.Sub_Menu_Label}</Link>}
                                            </React.Fragment>
                                        )}
                                    </div>
                                </Form>
                               
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="banner-img" style={{ height : `${!renderComponent ? ImageHeight+"px" : "auto"}`,backgroundColor:"#d3d1d0" }} >
                    
                    {renderComponent && <>
                            {props?.data?.Banner_Image?.url &&  <ImageTransform
                                imagesources={props?.data?.Banner_Image?.url}
                                renderer="pic-src" imagename="articles.Banner_Image.home_banner"
                                attr={{ height:ImageHeight, width:ImgWidth, alt: props?.data?.Banner_Image?.alternativeText ? props?.data?.Banner_Image?.alternativeText : props?.data?.Title, className: 'm-0 new-class' }}
                                imagetransformresult={processedImages}
                                id={props?.data?._id}     
                                
                                lazyLoading="false"                 
                            />}
                        </>
                        }
                        <div className='banner-short-text'>
                        <Container className='google-review-block'>
                        <Row>
                            <Col>
                                <GoogleRating/>
                            </Col>
                        </Row>
                    </Container>
                            <h2>tlc Estate and Letting agents in <span className='banner-italic-short'>Kensington and Chelsea</span></h2>
                        </div>
                    
                </div>
            </section>
        </React.Fragment>

    )

}
export default HomepageBanner;